import { Continue, Intro, PwaInfo, WelcomePageBgColor } from '../../../shared/constants/constants';
import React, { useEffect, useState } from 'react';
import { StoreInfoState, fetchStoreInfoDataAsync } from '../../../redux/reducers/storeInfoSlice';
import { getDeviceToken, saveStoreId, saveXSwyTimeZone } from '../../../services/token.service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@albertsons/uds/molecule/Button';
import CarouselComponent from '../../../shared/carousel/components/carousel.component';
import LoginComponent from '../login/login.component';
import { RootState } from '../../../config/redux/store';
import { openAlertAsync } from '../../../redux/reducers/alertSlice';

const WelcomePageComponent: React.FC = () => {
  const [deviceToken, setDeviceToken] = useState<string | null>(null);
  const [showWelcome, setShowWelcome] = useState<boolean>(true);
  const [enableContinueBtn, setEnableContinueBtn] = useState<boolean>(false);

  const { storeInfo } = useSelector<RootState, StoreInfoState>((state) => state.globalVendorCheckIn.storeInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const storeId = searchParams.get('storeId') as string;

  const showLoginPage = () => {
    setShowWelcome(false);
  };

  useEffect(() => {
    if (deviceToken) {
      navigate('/checkin-summary');
    }
  }, [deviceToken, navigate]);

  useEffect(() => {
    getDeviceToken().then((token) => {
      setDeviceToken(token);
    });
  }, []);

  useEffect(() => {
    if (storeId) {
      saveStoreId(storeId);
    } else {
      const storeIdFromAPi = storeInfo?.stores?.length > 0 ? storeInfo?.stores[0]?.storeId : '';
      saveStoreId(storeIdFromAPi);
    }
  }, [storeId, storeInfo?.stores]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(fetchStoreInfoDataAsync({ lat: position.coords.latitude, lng: position.coords.longitude }));
          //for local testing
          // dispatch(fetchStoreInfoDataAsync({ lat: 37.696502273709186, lng: -121.9385954680138 }));
        },
        (error) => {
          if (storeId) {
            dispatch(fetchStoreInfoDataAsync({ storeId }));
          } else {
            dispatch(openAlertAsync('Please enable location services or provide a store ID to check in.'));
          }
        }
      );
    } else if (storeId) {
      dispatch(fetchStoreInfoDataAsync({ storeId }));
    } else {
      dispatch(openAlertAsync('Please enable location services or provide a store ID to check in.'));
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    const vendors = storeInfo?.stores?.length > 0 ? storeInfo?.stores[0]?.vendors : [];
    const purposes = storeInfo?.stores?.length > 0 ? storeInfo?.stores[0]?.purposes : [];
    if (vendors?.length > 0 && purposes?.length > 0) {
      setEnableContinueBtn(true);
    }
  }, [storeInfo?.stores]);

  useEffect(() => {
    if (storeInfo?.stores?.length > 0 && storeInfo?.stores[0]?.timezone) {
      saveXSwyTimeZone(storeInfo.stores[0].timezone);
    }
  }, [storeInfo?.stores]);

  return (
    <>
      {showWelcome ? (
        <div
          className='container mx-auto px-4 sm:px-6 lg:px-8'
          style={{
            backgroundColor: WelcomePageBgColor
          }}
        >
          <div className='flex justify-center items-center h-screen'>
            <div className='text-center'>
              <h1 className='text-xl text-white font-bold mb-4'>{Intro}</h1>
              <p className='text-lg text-white'>{PwaInfo}</p>
              <CarouselComponent></CarouselComponent>
              <div className='mt-4'></div>
              <Button variant='secondary' onClick={showLoginPage} disabled={!enableContinueBtn}>
                {Continue}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <LoginComponent />
      )}
    </>
  );
};

export default WelcomePageComponent;
