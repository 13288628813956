import { ErrorResponse } from '../error-response';
import { errorMessageDefault } from './messages';
import instance from './instanceCreate';

export async function postFileAsync<Values>(method: string, body: any): Promise<Values | undefined> {
  try {
    const response = await instance.post(method, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const typedResponse: Values = response.data;
    return typedResponse;
  } catch (error: any) {
    const errorResponse: ErrorResponse = {
      errors: error?.response?.data?.errors || errorMessageDefault,
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
      data: error?.response?.data?.data,
    };
    return Promise.reject(errorResponse);
  }
}
