import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../config/redux/store';
import { AlertState, closeAlertAsync } from '../../../redux/reducers/alertSlice';
import { StyledAlert } from './styledAlert.component';

export interface Props {}

export default function AlertComponent(props: Props): React.ReactElement<Props> {
  const alertState = useSelector<RootState, AlertState>((state) => state.alert);
  const dispacth = useDispatch();
  const handleClose = useCallback(() => {
    dispacth(closeAlertAsync());
  }, [dispacth]);

  useEffect(() => {
    if (alertState.Alert.isOpen) {
      const timer = setTimeout(() => {
        dispacth(closeAlertAsync());
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [alertState.Alert.isOpen, dispacth]);

  return (
    <>
      {alertState.Alert.isOpen && (
        <StyledAlert
          isOpen={alertState.Alert.isOpen}
          sticky={true}
          onClose={handleClose}
          dismissible={true}
          variant='error'
        >
          {alertState.Alert.label}
        </StyledAlert>
      )}
    </>
  );
}
