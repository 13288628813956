import { combineReducers, Reducer } from '@reduxjs/toolkit';

import { storeInfoReducer, StoreInfoState } from './reducers/storeInfoSlice';
import { vendorCheckInReducer, VendorCheckInState } from './reducers/vendorCheckInSlice';
import { checkInSummaryReducer, CheckInSummaryState } from './reducers/checkInSummarySlice';

export interface GlobalVendorCheckInState {
  vendorCheckIn: VendorCheckInState;
  storeInfo: StoreInfoState;
  checkInSummary: CheckInSummaryState;
}

export const globalVendorCheckInReducer: Reducer<GlobalVendorCheckInState> = combineReducers({
  vendorCheckIn: vendorCheckInReducer,
  storeInfo: storeInfoReducer,
  checkInSummary: checkInSummaryReducer
});
