import { ErrorMessage } from '../error-message';

const REACT_APP_API_REQUEST_GENERIC_ERROR_MESSAGE =
  window.__RUNTIME_CONFIG__?.REACT_APP_API_REQUEST_GENERIC_ERROR_MESSAGE ??
  process.env.REACT_APP_API_REQUEST_GENERIC_ERROR_MESSAGE;

export const errorMessageDefault: Array<ErrorMessage> = [
  {
    errorCode: '0',
    errorMessage: REACT_APP_API_REQUEST_GENERIC_ERROR_MESSAGE,
  },
];
