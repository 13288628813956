import { RootState } from '../../../config/redux/store';
import { useSelector } from 'react-redux';
import Progress from '@albertsons/uds/molecule/Progress';
import Modal from '@albertsons/uds/molecule/Modal';
import { LoaderState } from '../../../redux/reducers/loaderSlice';

interface Props {}

export const LoaderComponent: React.FC<Props> = (props: Props) => {
  const loaderState = useSelector<RootState, LoaderState>((state) => state.loader);

  if (!loaderState.Loader.isLoading) {
    return null;
  }

  return (
    <div>
      <Modal isOpen={loaderState.Loader.isLoading} minHeight={150} minWidth={250}>
        {({ close }) => (
          <div className='h-full min-h-[150px] min-w-[225px]'>
            <div className='absolute inset-0 flex items-center justify-center'>
              <Progress value={0} variant='circular' status='active' max={100} indeterminate />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LoaderComponent;
