import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Loader } from '../../models/loader-model';

export interface LoaderState {
  Loader: Loader;
}

export const initialState: LoaderState = {
  Loader: {
    isLoading: false
  }
};

export const startLoaderAsync = createAsyncThunk('loader/startLoaderAsync', async (_, { dispatch }) => {
  dispatch(loaderActions.setLoading({ isLoading: true }));
});

export const stopLoaderAsync = createAsyncThunk('loader/stopLoaderAsync', async (_, { dispatch }) => {
  dispatch(loaderActions.setLoading({ isLoading: false }));
});

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<Loader>) => {
      state.Loader = action.payload;
    }
  }
});

export const loaderActions = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;
