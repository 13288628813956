import { AxiosRequestConfig } from 'axios';
import { ErrorResponse } from '../error-response';
import { Response } from '../model';
import { errorMessageDefault } from './messages';
import instance from './instanceCreate';

/**
 * @description
 ** EN: Base method for GET requests
 * @param method
 ** EN API method to be consumed. EX: baseURL/{method} = baseURL/access
 ** NOTE: In case of the baseURL is not configured in the application
 * must be passed the entire URL. EX: https://localhost:44336/api/access
 */
export async function getAsync<Values>(method: string): Promise<Values | undefined>;

/**
 * @description
 ** EN API method to be consumed. EX: baseURL/{method} = baseURL/access
 ** NOTE: In case of the baseURL is not configured in the application
 * must be passed the entire URL. EX: https://localhost:44336/api/access
 * @param params
 ** EN: the URL parameters to be sent with the request
 ** NOTE: params that are null or undefined are not rendered in the URL.
 ** EX: { ID: 12345 }
 */
export async function getAsync<Values>(method: string): Promise<Values | undefined>;
export async function getAsync<Values>(method: string, params: any): Promise<Values | undefined>;
export async function getAsync<Values>(method: string, params: any, headers: any): Promise<Values | undefined>;
export async function getAsync<Values>(method: string, params?: any, headers?: any): Promise<Values | undefined> {
  try {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: params,
      headers: headers
    };
    const response: Response = await instance.get(method, axiosRequestConfig);
    const typedResponse: Values = response.data;
    return typedResponse;
  } catch (error: any) {
    const errorResponse: ErrorResponse = {
      errors: error?.response?.data?.errors || errorMessageDefault,
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
      errorMessage: error?.response?.data?.errorMessage
    };
    return Promise.reject(errorResponse);
  }
}
