import React from 'react';

export const LocationImg: React.FC = () => (
  <svg width='152' height='152' viewBox='0 0 152 152' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='mask0_82_476'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='152'
      height='152'
    >
      <circle cx='76' cy='76' r='76' fill='#FBE4E6' />
    </mask>
    <g mask='url(#mask0_82_476)'>
      <circle cx='76' cy='76' r='76' fill='#F6F2FE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M112.563 17.0379L95.1212 -24.0088L99.4929 -25.8664L117.313 16.0706V51.6125H182.875V56.3625H118.299L144.143 129.237L139.667 130.825L113.26 56.3625H81.6777V114.594H76.9277V88.0247L49.1564 105.579V160.906H44.4064V95.2526L-2.1269 78.6965H-19.4639V73.9465H-1.30707L44.4064 90.2109V66.5597C39.4552 65.4373 35.6072 61.4091 34.75 56.3625H23.122L10.8641 42.1505H-19.4639V37.4005H13.0399L25.2979 51.6125H34.8422C35.8566 46.7942 39.6149 42.9866 44.4064 41.9005V28.0083L19.0371 7.54605V-24.9376H23.7871V5.27476L38.7393 17.3348L69.4501 -5.61992L61.9135 -24.0382L66.3097 -25.837L86.43 23.3339H112.563V17.0379ZM59.5841 51.6125C58.5095 46.5083 54.3557 42.5384 49.1564 41.7362V25.737L42.5553 20.4128L71.3083 -1.07863L83.2414 28.0839H112.563V51.6125H59.5841ZM49.1564 66.7239C54.5193 65.8965 58.7698 61.6988 59.6763 56.3625H76.9277V82.4053L49.1564 99.9594V66.7239Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M112.563 17.0379L95.1212 -24.0088L99.4929 -25.8664L117.313 16.0706V51.6125H182.875V56.3625H118.299L144.143 129.237L139.667 130.825L113.26 56.3625H81.6777V114.594H76.9277V88.0247L49.1564 105.579V160.906H44.4064V95.2526L-2.1269 78.6965H-19.4639V73.9465H-1.30707L44.4064 90.2109V66.5597C39.4552 65.4373 35.6072 61.4091 34.75 56.3625H23.122L10.8641 42.1505H-19.4639V37.4005H13.0399L25.2979 51.6125H34.8422C35.8566 46.7942 39.6149 42.9866 44.4064 41.9005V28.0083L19.0371 7.54605V-24.9376H23.7871V5.27476L38.7393 17.3348L69.4501 -5.61992L61.9135 -24.0382L66.3097 -25.837L86.43 23.3339H112.563V17.0379ZM59.5841 51.6125C58.5095 46.5083 54.3557 42.5384 49.1564 41.7362V25.737L42.5553 20.4128L71.3083 -1.07863L83.2414 28.0839H112.563V51.6125H59.5841ZM49.1564 66.7239C54.5193 65.8965 58.7698 61.6988 59.6763 56.3625H76.9277V82.4053L49.1564 99.9594V66.7239Z'
        stroke='#D2C2F2'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M62.5331 96.2371C60.3585 93.8291 42.927 83.2974 46.0201 78.9278C48.5493 75.3515 51.8064 71.7242 52.6714 70.346C53.5364 68.9677 56.5416 63.5028 60.6503 65.8692C64.759 68.2356 86.1369 85.6247 88.9539 87.8082C91.7709 89.9918 95.7526 92.2598 93.6557 95.7591C91.5587 99.2584 81.3 108.526 81.3 108.526C81.3 108.526 73.0104 107.841 62.5331 96.2371Z'
        fill='#130B06'
      />
      <path
        d='M96.2344 93.4101C94.5465 98.1803 92.1777 103.123 87.8777 107.477C89.6741 110.715 94.9319 113.184 95.9613 122.045C99.4678 127.839 123.998 138.24 125.001 139.328C126.003 140.416 119.04 156.544 112.791 155.081C106.543 153.619 79.0404 125.229 76.6883 123.101C70.2083 117.858 66.1353 100.687 60.1831 88.4873C59.7242 87.5503 60.8869 86.6399 61.6701 87.326C64.7677 90.0431 68.9412 97.2119 71.2542 101.466C71.4528 101.828 72.0064 101.631 71.9291 101.221C70.8555 95.6085 69.6346 85.6713 69.4872 81.0701C69.4765 80.7344 69.7832 80.4698 70.1158 80.5357C75.8147 81.6514 76.4498 103.376 77.2929 102.478C78.5609 101.118 81.4061 81.4421 81.7833 80.5549C82.1641 79.6704 83.6481 79.7653 84.3837 82.1585C85.1253 84.5733 84.9322 96.8009 83.1875 103.909C83.1102 104.227 83.4975 104.448 83.7252 104.213C86.0032 101.841 92.1885 95.3536 95.1546 91.7378C96.482 90.1197 96.8352 91.7029 96.2344 93.4101Z'
        fill='#79403B'
      />
      <path
        d='M60.0165 83.1189C60.1893 84.4833 64.9277 88.1561 66.085 84.5438C67.2424 80.9316 65.0838 80.2799 62.4976 79.9176C59.9115 79.5554 60.0165 83.1189 60.0165 83.1189Z'
        fill='#7C7575'
      />
      <path
        d='M57.8725 70.0815C58.2304 70.5621 59.7507 71.9493 60.6477 71.0023C61.5447 70.0553 61.0786 68.8756 60.5219 68.4441C59.9651 68.0125 59.1327 66.8876 57.8723 68.3035C56.6118 69.7195 57.8725 70.0815 57.8725 70.0815Z'
        fill='#D8D4D2'
      />
      <path
        d='M63.0166 93.1971C63.1916 93.0731 63.2993 92.8687 63.2911 92.6536C63.2077 90.5402 61.6777 87.3225 61.6777 87.3225C60.8945 86.6365 59.7318 87.5468 60.1907 88.4839C60.9166 89.9721 61.6806 91.5858 62.4667 93.2731C62.4667 93.2731 62.4667 93.2731 62.4702 93.2758C62.5546 93.4654 62.8118 93.3375 63.0166 93.1971Z'
        fill='#EDB2B2'
      />
      <path
        d='M96.237 148.887C96.237 148.887 104.295 169.812 104.583 172.084C104.871 174.356 127.56 158.508 128.966 149.551C130.371 140.595 135.529 130.24 135.145 129.942C134.761 129.644 126.006 119.167 110.849 124.023C106.464 125.427 96.237 148.887 96.237 148.887Z'
        fill='#A28AD1'
      />
      <path
        d='M96.3064 122.554C95.7031 126.726 95.7947 132.206 91.3824 137.506C91.3824 137.506 100.274 137.857 102.17 126.673C98.8125 124.641 96.3064 122.554 96.3064 122.554Z'
        fill='#130B06'
      />
      <path
        d='M90.8034 137.818C87.5811 143.068 90.2062 147.083 95.2094 148.981C100.213 150.88 110.933 141.433 113.806 133.709C116.68 125.985 111.565 124.566 111.565 124.566C111.565 124.566 101.829 114.843 100.546 123.43C99.2632 132.018 90.8034 137.818 90.8034 137.818Z'
        fill='#A28AD1'
      />
      <path
        d='M90.8033 137.818C92.5029 141.968 96.0836 147.726 99.5479 148.593'
        stroke='#6B47B3'
        strokeWidth='1.01241'
        strokeMiterlimit='10'
      />
      <path
        d='M94.7142 134.363C98.9406 137.639 102.414 142.316 104.473 145.633'
        stroke='#6B47B3'
        strokeWidth='1.01241'
        strokeMiterlimit='10'
      />
      <path
        d='M98.4895 129.487C102.716 132.763 108.068 137.047 110.127 140.363'
        stroke='#6B47B3'
        strokeWidth='1.01241'
        strokeMiterlimit='10'
      />
      <path
        d='M99.5963 126.648C104.781 127.686 108.814 129.032 112.383 130.615'
        stroke='#6B47B3'
        strokeWidth='1.01241'
        strokeMiterlimit='10'
      />
      <path
        d='M96.754 149.31C96.754 149.31 117.819 144.549 111.176 123.681C107.841 122.13 106.055 121.513 100.453 122.751'
        stroke='#6B47B3'
        strokeWidth='1.01241'
        strokeMiterlimit='10'
      />
      <path
        d='M70.3386 85.9827C70.5482 85.9307 70.7193 85.7755 70.7886 85.5696C71.3347 83.9329 71.3006 81.807 71.2685 81.0766C70.9089 80.7979 70.5255 80.608 70.1181 80.5293C69.7855 80.4633 69.4816 80.7244 69.4896 81.0636C69.5317 82.3491 69.6554 84.0535 69.8366 85.9605C69.9287 86.0601 70.145 86.0359 70.3386 85.9827Z'
        fill='#EDB2B2'
      />
      <path
        d='M81.7867 80.5517C81.6671 80.8372 81.2871 83.0823 80.7862 86.0295C80.8721 86.1299 81.0975 86.1523 81.2966 86.1429C81.5278 86.1359 81.7474 86.0127 81.8648 85.8102C83.0273 83.8443 83.4355 80.8929 83.4355 80.8929C83.4355 80.8929 83.4544 80.5407 83.224 80.2774C82.6141 79.8555 82.01 80.0306 81.7867 80.5517Z'
        fill='#EDB2B2'
      />
      <path
        d='M95.1587 91.7347C94.3736 92.6894 93.3603 93.8511 92.2568 95.0783C92.3011 95.1013 92.3418 95.1216 92.3853 95.1383C92.5998 95.2256 92.8517 95.2064 93.0385 95.0747C95.2409 94.7191 96.0955 91.9021 96.4401 91.3847C96.2797 90.9217 95.8556 90.8865 95.1587 91.7347Z'
        fill='#EDB2B2'
      />
      <rect
        width='3.12244'
        height='3.12244'
        rx='1.56122'
        transform='matrix(0.628957 -0.77744 0.777401 0.629005 61.5674 80.67)'
        fill='#130B06'
      />
      <path
        d='M32.7922 33.8748C32.7359 31.9395 33.073 30.013 33.7831 28.2118C34.8427 25.4976 36.6966 23.1662 39.1025 21.5226C41.5084 19.8791 44.3545 19 47.2682 19C50.1819 19 53.0277 19.8791 55.4336 21.5226C57.8395 23.1662 59.6935 25.4976 60.753 28.2118C61.4631 30.013 61.8002 31.9395 61.744 33.8748C61.744 43.9265 54.8706 53.5252 47.2752 59.3581C39.8708 53.0651 32.7922 43.2965 32.7922 33.8748Z'
        fill='#E84646'
      />
      <path
        d='M47.2396 39.4241C46.047 39.424 44.8809 39.0711 43.8883 38.4098C42.8958 37.7485 42.1208 36.8084 41.6614 35.7078C41.2084 34.6031 41.088 33.3902 41.3147 32.218C41.5499 31.0489 42.1257 29.9753 42.9696 29.1327C43.8135 28.29 44.888 27.7158 46.0575 27.4822C46.9323 27.3085 47.8346 27.331 48.6996 27.5479C49.5647 27.7649 50.3708 28.1709 51.06 28.7369C51.7492 29.3029 52.3045 30.0148 52.6855 30.8211C53.0666 31.6275 53.2638 32.5083 53.2634 33.4001C53.27 34.193 53.1186 34.9793 52.8183 35.7131C52.5179 36.447 52.0745 37.1138 51.5138 37.6745C50.9531 38.2352 50.2865 38.6785 49.5527 38.9789C48.8188 39.2793 48.0326 39.4307 47.2396 39.4241Z'
        fill='white'
      />
    </g>
  </svg>
);
