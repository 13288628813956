import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startLoaderAsync, stopLoaderAsync } from './loaderSlice';
import { fetchStoreInfoData } from '../../services/store-info.service';
import { openAlertAsync } from './alertSlice';
import { ErrorResponse } from '../../config/request/error-response';
import { FetchStoreInfoParams, StoreInfoModel } from '../../models/vendor-checkIn-model';

export interface StoreInfoState {
  storeInfo: StoreInfoModel;
}

export const initialState: StoreInfoState = {
  storeInfo: {
    statusCode: null,
    stores: []
  }
};

export const fetchStoreInfoDataAsync = createAsyncThunk(
  'store-info/fetchStoreInfoData',
  async (params: FetchStoreInfoParams, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startLoaderAsync());
      const response = await fetchStoreInfoData(params);
      return response;
    } catch (error: any) {
      const errorResponse: ErrorResponse = error;
      dispatch(openAlertAsync(errorResponse?.errorMessage || errorResponse?.errors[0]?.errorMessage));
      return rejectWithValue(error);
    } finally {
      dispatch(stopLoaderAsync());
    }
  }
);

const storeInfoSlice = createSlice({
  name: 'storeInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStoreInfoDataAsync.fulfilled, (state, action) => {
      state.storeInfo = action.payload as StoreInfoModel;
    });
  }
});

export const storeInfoReducer = storeInfoSlice.reducer;
