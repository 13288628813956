import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert } from '../../models/alert-model';

export interface AlertState {
  Alert: Alert;
}

export const initialState: AlertState = {
  Alert: {
    isOpen: false,
    label: ''
  }
};

export const openAlertAsync = createAsyncThunk('alert/openAlertAsync', async (label: string, { dispatch }) => {
  dispatch(alertActions.openAlert({ isOpen: true, label }));
});

export const closeAlertAsync = createAsyncThunk('alert/closeAlertAsync', async (_, { dispatch }) => {
  dispatch(alertActions.closeAlert({ isOpen: false, label: '' }));
});

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    openAlert: (state, action: PayloadAction<Alert>) => {
      state.Alert = action.payload;
    },
    closeAlert: (state, action: PayloadAction<Alert>) => {
      state.Alert = action.payload;
    }
  }
});

export const alertActions = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
