import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { startLoaderAsync, stopLoaderAsync } from './loaderSlice';
import { postVendorCheckInData, postVendorCheckoutData } from '../../services/vendor-checkin-checkout.service';
import { ErrorResponse } from '../../config/request/error-response';
import { openAlertAsync } from './alertSlice';
import {
  APIAsyncHeaders,
  CheckoutBodyModel,
  RegisterVendorCheckInParams,
  VendorCheckInModel
} from '../../models/login-model';
import { generateNewDeviceToken } from '../../shared/generateToken';
import { saveDeviceToken } from '../../services/token.service';

export interface VendorCheckInState {
  vendorCheckIn: VendorCheckInModel;
}

export const initialState: VendorCheckInState = {
  vendorCheckIn: {
    firstName: '',
    lastName: '',
    vendorId: '',
    deviceToken: '',
    notes: '',
    purposeId: '',
    checkinTime: ''
  }
};

export const postVendorCheckInDataAsync = createAsyncThunk(
  'vendorCheckIn/postVendorCheckInDataAsync',
  async (
    { vendorCheckIn, headers }: { vendorCheckIn: RegisterVendorCheckInParams; headers: APIAsyncHeaders },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(startLoaderAsync());
      const { checkinTime, ...vendorCheckInData } = vendorCheckIn;
      await postVendorCheckInData(vendorCheckInData, headers);
      return { ...vendorCheckInData, checkinTime };
    } catch (error: any) {
      const errorResponse: ErrorResponse = error;
      dispatch(openAlertAsync(errorResponse?.errorMessage ?? errorResponse?.errors[0]?.errorMessage));
      return rejectWithValue(errorResponse);
    } finally {
      dispatch(stopLoaderAsync());
    }
  }
);

const handleVendorCheckIn = async (
  vendorCheckIn: RegisterVendorCheckInParams,
  headers: APIAsyncHeaders,
  dispatch: any,
  rejectWithValue: any
) => {
  try {
    dispatch(startLoaderAsync());
    const deviceToken = generateNewDeviceToken();
    const { checkinTime, ...vendorCheckInData } = vendorCheckIn;
    vendorCheckInData.deviceToken = deviceToken;
    await postVendorCheckInData(vendorCheckInData, headers);
    await saveDeviceToken(deviceToken);
    return { ...vendorCheckInData, deviceToken, checkinTime };
  } catch (error: any) {
    const errorResponse: ErrorResponse = error;
    dispatch(openAlertAsync(errorResponse?.errorMessage || errorResponse?.errors[0]?.errorMessage));
    return rejectWithValue(errorResponse);
  } finally {
    dispatch(stopLoaderAsync());
  }
};

export const postRegisterVendorCheckInDataAsync = createAsyncThunk(
  'vendorCheckIn/postRegisterVendorCheckInDataAsync',
  async (
    { vendorCheckIn, headers }: { vendorCheckIn: RegisterVendorCheckInParams; headers: APIAsyncHeaders },
    { dispatch, rejectWithValue }
  ) => handleVendorCheckIn(vendorCheckIn, headers, dispatch, rejectWithValue)
);

export const postVendorCheckoutDataAsync = createAsyncThunk(
  'vendorCheckIn/postVendorCheckoutDataAsync',
  async (
    { checkoutData, headers }: { checkoutData: CheckoutBodyModel; headers: APIAsyncHeaders },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(startLoaderAsync());
      await postVendorCheckoutData(checkoutData, headers);
      return checkoutData;
    } catch (error: any) {
      const errorResponse: ErrorResponse = error;
      dispatch(openAlertAsync(errorResponse?.errorMessage || errorResponse?.errors[0]?.errorMessage));
      return rejectWithValue(errorResponse);
    } finally {
      dispatch(stopLoaderAsync());
    }
  }
);

const vendorCheckInSlice = createSlice({
  name: 'vendorCheckIn',
  initialState,
  reducers: {
    setCheckinTime: (state, action) => {
      state.vendorCheckIn.checkinTime = action.payload; // Set check-in time
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      postRegisterVendorCheckInDataAsync.fulfilled,
      (state, action: PayloadAction<VendorCheckInModel>) => {
        state.vendorCheckIn = action.payload;
      }
    );

    builder.addCase(postVendorCheckoutDataAsync.fulfilled, (state) => {
      state.vendorCheckIn = initialState.vendorCheckIn;
    });

    builder.addCase(postVendorCheckInDataAsync.fulfilled, (state, action: PayloadAction<VendorCheckInModel>) => {
      state.vendorCheckIn = action.payload;
    });
  }
});

export const vendorCheckInReducer = vendorCheckInSlice.reducer;
export const { setCheckinTime } = vendorCheckInSlice.actions;
