import styled from 'styled-components';
import Alert from '@albertsons/uds/molecule/Alert';

export const StyledAlert = styled(Alert)`
  max-width: 100%;
  width: auto;
  padding: 1rem;
  box-sizing: border-box;

  @media (min-width: 600px) {
    max-width: 80%;
  }

  @media (min-width: 900px) {
    max-width: 60%;
  }
`;
