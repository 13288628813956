import styled from 'styled-components';
import Button from '@albertsons/uds/molecule/Button'; // Adjust the import path as necessary

export const SuccessButton = styled(Button)`
  background-color: #28a745; /* Bootstrap success color */
  border-color: #28a745;
  color: white;

  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }

  &:active {
    background-color: #1e7e34;
    border-color: #1c7430;
  }

  &:focus {
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }

  &:disabled {
    background-color: #c3e6cb;
    border-color: #c3e6cb;
    color: #6c757d;
  }
`;

export default SuccessButton;
