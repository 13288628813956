// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Nunito Sans', sans-serif;
  -webkit-overflow-scrolling: touch;
  overflow: auto !important;
}

.greenbutton {
  color: #fff;
  background-color: #009963;
  border-color: #009963;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,sCAAsC;EACtC,iCAAiC;EACjC,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');\n\nbody {\n  font-family: 'Nunito Sans', sans-serif;\n  -webkit-overflow-scrolling: touch;\n  overflow: auto !important;\n}\n\n.greenbutton {\n  color: #fff;\n  background-color: #009963;\n  border-color: #009963;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
