export const checkLastCheckIn = (checkIns) => {
  if (checkIns?.length === 0) return false;

  const lastCheckIn = checkIns[checkIns.length - 1];
  const currentTime = new Date().toISOString();

  const isCheckInValid = new Date(lastCheckIn.checkinTs) < new Date(currentTime); //api call checkintime < current time
  const isCheckoutMissing = !lastCheckIn.hasOwnProperty('checkoutTs');

  return isCheckInValid && isCheckoutMissing;
};
