import { Provider } from 'react-redux';
import React from 'react';
import Routers from './config/routers';
import store from './config/redux/store';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LoaderComponent from './shared/loader/components/loader.component';
import AlertComponent from './shared/alert/components/alert.component';

function Root() {
  return (
    // <React.StrictMode>
    <Provider store={store}>
      <LoaderComponent />
      <AlertComponent />
      <Routers />
    </Provider>
    // </React.StrictMode>
  );
}

export default Root;
