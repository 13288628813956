import { ErrorResponse } from '../error-response';
import { errorMessageDefault } from './messages';
import instance from './instanceCreate';

/**
 * @description
 ** EN: Base method for PUT requests
 * @param method
 ** EN API method to be consumed. EX: baseURL/{method} = baseURL/access
 ** NOTE: In case of the baseURL is not configured in the application
 * must be passed the entire URL. EX: https://localhost:44336/api/access
 * @param body
 ** EN: Object that will be send in the body of request
 */
export async function putAsync<Values>(method: string, body: any): Promise<Values | undefined> {
  try {
    const response = await instance.put(method, body);
    const typedResponse: Values = response.data;
    return typedResponse;
  } catch (error: any) {
    const errorResponse: ErrorResponse = {
      errors: error?.response?.data?.errors || errorMessageDefault,
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
    };
    return Promise.reject(errorResponse);
  }
}
