import axios from 'axios';
import { requestInterceptor } from './interceptors';

const baseUrl = window.__RUNTIME_CONFIG__?.REACT_APP_MODULE_API_URL ?? process.env.REACT_APP_MODULE_API_URL;

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': process.env.PUBLIC_URL,
  },
});

instance.interceptors.request.use(requestInterceptor);

export default instance;
