import { configureStore } from '@reduxjs/toolkit';
import { globalVendorCheckInReducer } from '../../redux';
import { alertReducer } from '../../redux/reducers/alertSlice';
import { loaderReducer } from '../../redux/reducers/loaderSlice';

const store = configureStore({
  reducer: {
    loader: loaderReducer,
    alert: alertReducer,
    globalVendorCheckIn: globalVendorCheckInReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  devTools: {
    trace: true,
    traceLimit: 25
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
