import { LoginFormModel } from '../models/login-model';

const deviceToken = '@vendor-checkin:deviceToken';
const storeIdString = '@vendor-checkin:storeId';
const xSwyTimeZone = '@vendor-checkin:x-swy-timezone';

export const saveToken = async (token: string): Promise<void> => {
  return Promise.resolve(saveDeviceToken(token));
};

export function saveDeviceToken(token: string): Promise<any> {
  return new Promise((resolve, _) => {
    resolve(localStorage.setItem(deviceToken, token));
  });
}

export function getDeviceToken(): Promise<any> {
  return new Promise((resolve, _) => {
    resolve(localStorage.getItem(deviceToken));
  });
}

export const saveUserInfoamtion = (userInfo: LoginFormModel): void => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const getUserInfo = (): LoginFormModel => {
  const userInfoString = localStorage.getItem('userInfo');
  return userInfoString ? JSON.parse(userInfoString) : null;
};

export const saveStoreId = async (storeId: string): Promise<void> => {
  return Promise.resolve(saveStoreIdFromUrl(storeId));
};

export function saveStoreIdFromUrl(storeId: string): Promise<any> {
  return new Promise((resolve, _) => {
    resolve(localStorage.setItem(storeIdString, storeId));
  });
}

export function getStoreId(): Promise<any> {
  return new Promise((resolve, _) => {
    resolve(localStorage.getItem(storeIdString));
  });
}

export function saveXSwyTimeZone(timezone: string): void {
  localStorage.setItem(xSwyTimeZone, timezone);
}

export function getXSwyTimeZone(): string {
  return localStorage.getItem(xSwyTimeZone) as string;
}
