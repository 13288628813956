import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import Link from '@albertsons/uds/molecule/Link';

const PageNotFound: React.FC = () => {
  return (
    <div className='max-w-4xl mx-auto px-4 sm:px-6 lg:px-8'>
      <h2>
        {' '}
        The page you are looking for does not exist. Please click{' '}
        <Link>
          <RouterLink to='/' className='text-blue-600 hover:text-blue-800'>
            {' '}
            here{' '}
          </RouterLink>
        </Link>{' '}
        to return to the homepage.
      </h2>
    </div>
  );
};

export default PageNotFound;
