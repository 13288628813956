import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import './checkin-summary.scss';
import BannerBottom from '../banner-bottom/banner-bottom';
import { RootState } from '../../../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceToken, getStoreId, saveStoreId } from '../../../services/token.service';
import { CheckInSummaryState, fetchCheckinsSummaryDataAsync } from '../../../redux/reducers/checkInSummarySlice';
import { VendorCheckInState } from '../../../redux/reducers/vendorCheckInSlice';
import SuccessButton from '../../../shared/styled-components/styled-buttons';
import { TableContainer, Table, Th, Td, GreenText } from './checkin-summary.styled';
import {
  AutoCheckedOut,
  CheckInSummary,
  CheckInSummaryTableColumn,
  CheckOut,
  CheckOutusingNFC,
  LocationSrvcsHeading,
  TotalCheckIns,
  TotalCheckouts,
  TotalDuration
} from '../../../shared/constants/constants';
import { formatDate, formatTime } from '../../../shared/format-date/format-date';
import { StoreInfoState, fetchStoreInfoDataAsync } from '../../../redux/reducers/storeInfoSlice';
import { openAlertAsync } from '../../../redux/reducers/alertSlice';

const CheckInSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [vendorName, setVendorName] = useState<string | null>(null);
  const [location, setLocation] = useState<{ lat: number | null; lng: number | null }>({ lat: null, lng: null });
  const [distanceWithinHundredMts, setDistanceWithinHundredMts] = useState<boolean>(false);
  const [triggerGeolocation, setTriggerGeolocation] = useState(false);

  const { vendorCheckIn } = useSelector<RootState, VendorCheckInState>(
    (state) => state.globalVendorCheckIn.vendorCheckIn
  );
  const { storeInfo } = useSelector<RootState, StoreInfoState>((state) => state.globalVendorCheckIn.storeInfo);

  const { checkInSummary } = useSelector<RootState, CheckInSummaryState>(
    (state) => state.globalVendorCheckIn.checkInSummary
  );

  const { checkIns, totalCheckins, totalCheckouts, totalDuration } = checkInSummary;

  const handleEnableClick = () => {
    // navigate('/checkin-detail');
    setTriggerGeolocation(!triggerGeolocation);
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  useEffect(() => {
    getDeviceToken().then((token) => {
      if (!token) {
        navigate('/');
      } else {
        dispatch(fetchCheckinsSummaryDataAsync({ deviceToken: token }));
      }
    });
  }, [dispatch, navigate]);

  useEffect(() => {
    if (storeInfo.stores?.length > 0 && storeInfo.stores[0].storeId) {
      saveStoreId(storeInfo.stores[0].storeId);
    }
  }, [storeInfo.stores]);

  const prevLocation = useRef<{ lat: number | null; lng: number | null }>({ lat: null, lng: null });

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // in metres
    return distance;
  };

  useEffect(() => {
    if (navigator.geolocation) {
      const watcher = navigator.geolocation.watchPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          if (prevLocation.current.lat === null && prevLocation.current.lng === null) {
            // First fetched coordinates on page load
            dispatch(fetchStoreInfoDataAsync(newLocation));
            //for local testing
            // dispatch(fetchStoreInfoDataAsync({ lat: 61.21676891, lng: -149.8185359 }));
          } else {
            const distance = calculateDistance(
              prevLocation.current.lat,
              prevLocation.current.lng,
              newLocation.lat,
              newLocation.lng
            );

            if (distance > 10) {
              console.log('Distance is greater than 10 meters', distance);
              dispatch(fetchStoreInfoDataAsync(newLocation));
              //for local testing
              // dispatch(fetchStoreInfoDataAsync({ lat: 61.21676891, lng: -149.8185359 }));
            }
          }
          prevLocation.current = newLocation;
          setLocation(newLocation);
        },
        async (error) => {
          console.error('Error watching position:', error);
          getStoreId().then((storeId) => {
            if (storeId) {
              dispatch(fetchStoreInfoDataAsync({ storeId }));
            } else {
              dispatch(openAlertAsync('Please enable location services or provide a store ID to check in.'));
            }
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );

      // Cleanup watcher on component unmount
      return () => {
        navigator.geolocation.clearWatch(watcher);
      };
    } else {
      console.error('Geolocation is not supported by this browser.');
      dispatch(openAlertAsync('Geolocation is not supported by this browser.'));
    }
  }, [dispatch, triggerGeolocation]);

  useEffect(() => {
    if (checkIns?.length > 0) {
      const vendorName = checkIns[0]?.vendorUser?.vendor?.company;
      setVendorName(vendorName);
    }
  }, [checkIns]);

  useEffect(() => {
    const checkDistanceAndNavigate = () => {
      if (!vendorCheckIn.isCheckedIn && storeInfo.stores?.length > 0) {
        const distance = calculateDistance(
          location.lat,
          location.lng,
          storeInfo.stores[0].latitude,
          storeInfo.stores[0].longitude
        );

        console.log('diatance between co-ords', distance);

        if (distance <= 100) {
          setDistanceWithinHundredMts(true);
          navigate('/checkin-detail');
        }
      }
    };

    checkDistanceAndNavigate();
  }, [location.lat, location.lng, navigate, storeInfo.stores, vendorCheckIn.isCheckedIn]);

  const renderCheckoutStatus = (
    checkoutTs: string | null,
    autoCheckedOut: boolean,
    distanceWithinHundredMts: boolean
  ) => {
    if (checkoutTs) {
      return formatTime(checkoutTs);
    } else if (autoCheckedOut) {
      return <strong>{AutoCheckedOut}</strong>;
    } else {
      return distanceWithinHundredMts ? <SuccessButton onClick={handleCheckout}>{CheckOut}</SuccessButton> : 'N/A';
    }
  };

  return (
    <div className='checkin-summary-page'>
      <nav className='navbar'>
        <button className='back-button' onClick={() => navigate('/')}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h1 className='page-title'>{CheckInSummary}</h1>
      </nav>
      <div className='checkin-summary-content'>
        <h1>{vendorName}</h1>
        <div className='total-box'>
          <div>
            <span className='heading'>{TotalDuration}</span>
            <span className='timebox'>{totalDuration} hrs</span>
          </div>
          <div>
            <span className='heading'>{TotalCheckIns}</span>
            <span className='timebox'>{totalCheckins}</span>
          </div>
          <div>
            <span className='heading'>{TotalCheckouts}</span>
            <span className='timebox'>{totalCheckouts}</span>
          </div>
        </div>

        {checkIns && checkIns.length > 0 && (
          <TableContainer className='table-responsive'>
            <Table>
              <thead>
                <tr>
                  {CheckInSummaryTableColumn.map((column) => {
                    return (
                      <Th key={column.id}>
                        <span className='flex items-center'>
                          {column.icon} {column.name}
                        </span>
                      </Th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {checkIns.map((checkIn) => {
                  const { checkinId, storeId, checkinTs, checkoutTs, autoCheckedOut } = checkIn;
                  return (
                    <tr key={checkinId}>
                      <Td>{storeId}</Td>
                      <Td>{formatDate(checkinTs)}</Td>
                      <Td>
                        <GreenText>{checkinTs ? formatTime(checkinTs) : 'N/A'}</GreenText>
                      </Td>
                      <Td>
                        <GreenText>
                          {' '}
                          {renderCheckoutStatus(checkoutTs as string, autoCheckedOut, distanceWithinHundredMts)}
                        </GreenText>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </div>
      {(!location?.lat || !location?.lng) && (
        <BannerBottom heading={LocationSrvcsHeading} subtext={CheckOutusingNFC} onEnableClick={handleEnableClick} />
      )}
    </div>
  );
};

export default CheckInSummaryPage;
