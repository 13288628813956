import React, { useState, useEffect } from 'react';
import { ButtonContainer, IconWrapper, PageContainer, StyledIconWrapper } from './checkout.styled';
import { SuccessButton } from '../../../shared/styled-components/styled-buttons';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { CheckoutConfirmString, Confirm } from '../../../shared/constants/constants';
import { useDispatch } from 'react-redux';
import { getDeviceToken, getStoreId } from '../../../services/token.service';
import { postVendorCheckoutDataAsync } from '../../../redux/reducers/vendorCheckInSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const CheckoutComponent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deviceToken, setDeviceToken] = useState<string | null>(null);
  const [storeId, setStoreId] = useState<null | string>(null);

  const [searchParams] = useSearchParams();

  const storeIdFromParams = searchParams.get('storeId') as string;

  const handleCheckout = () => {
    const body = {
      deviceToken: deviceToken as string,
      storeId: storeId as string
    };
    dispatch(
      postVendorCheckoutDataAsync({
        checkoutData: body
      })
    )
      .unwrap()
      .then(() => {
        navigate('/checkin-summary');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDeviceToken().then((token) => {
      setDeviceToken(token);
    });
  });

  useEffect(() => {
    if (storeIdFromParams) {
      setStoreId(storeIdFromParams);
    } else {
      getStoreId().then((storeId) => {
        setStoreId(storeId);
      });
    }
  }, [storeIdFromParams]);

  return (
    <PageContainer>
      <nav className='navbar'>
        <button className='back-button' onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h1 className='page-title'>Check-out page</h1>
      </nav>

      <div className='flex flex-col items-center justify-between min-h-screen bg-gray-100'>
        <IconWrapper className='m-2'>
          <StyledIconWrapper>
            <IoIosCheckmarkCircle />
          </StyledIconWrapper>
        </IconWrapper>
        <h1 className='text-2xl font-bold mt-6'>{CheckoutConfirmString}</h1>
      </div>
      <ButtonContainer>
        <SuccessButton className='mb-2' type='button' onClick={handleCheckout}>
          {Confirm}
        </SuccessButton>
      </ButtonContainer>
    </PageContainer>
  );
};

export default CheckoutComponent;
