// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkin-detail-content .top-bar {
  display: flex;
  padding: 10px;
}
.checkin-detail-content .top-bar .left {
  flex: 0.5 1;
}
.checkin-detail-content .top-bar .left img {
  width: 100%;
}
.checkin-detail-content .top-bar .right {
  flex: 1 1;
  margin-left: 20px;
}
.checkin-detail-content .top-bar .right h1 {
  font-weight: bold;
  font-size: 28px;
}
.checkin-detail-content .content {
  padding: 20px;
}
.checkin-detail-content .content .greenbutton {
  margin-top: 10px;
  padding: 25px 20px;
  border-radius: 100px;
}
.checkin-detail-content .content h4 {
  font-size: 20px;
  font-weight: bold;
}
.checkin-detail-content .content p {
  margin-top: 10px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/vendor-checkin/components/check-in-detail/checkin-detail.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,aAAA;AAAR;AACQ;EACI,WAAA;AACZ;AAAY;EACI,WAAA;AAEhB;AACQ;EACI,SAAA;EACA,iBAAA;AACZ;AAAY;EACI,iBAAA;EACA,eAAA;AAEhB;AAEI;EACI,aAAA;AAAR;AACQ;EACI,gBAAA;EACA,kBAAA;EACA,oBAAA;AACZ;AACQ;EACI,eAAA;EACA,iBAAA;AACZ;AACQ;EACI,gBAAA;EACA,kBAAA;AACZ","sourcesContent":[".checkin-detail-content{\r\n    .top-bar{\r\n        display: flex;\r\n        padding: 10px;\r\n        .left{\r\n            flex: 0.5;\r\n            img{\r\n                width: 100%;\r\n            }\r\n        }\r\n        .right{\r\n            flex: 1;\r\n            margin-left: 20px;\r\n            h1{\r\n                font-weight: bold;\r\n                font-size: 28px;\r\n            }\r\n        }\r\n    }\r\n    .content{\r\n        padding: 20px;\r\n        .greenbutton{\r\n            margin-top: 10px;\r\n            padding: 25px 20px;\r\n            border-radius: 100px;\r\n        }\r\n        h4{\r\n            font-size: 20px;\r\n            font-weight: bold;\r\n        }\r\n        p{\r\n            margin-top: 10px;\r\n            text-align: center;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
