import { AxiosRequestConfig, ResponseType as axiosResponseType } from 'axios';

import { ErrorResponse } from '../error-response';
import { errorMessageDefault } from './messages';
import instance from './instanceCreate';

export async function getFileAsync(method: string, params?: any): Promise<any | undefined> {
  const responseType: axiosResponseType = 'blob';
  const axiosRequestConfig: AxiosRequestConfig = { responseType: responseType, params: params };
  try {
    var data = await instance.get(method, axiosRequestConfig);
    return data;
  } catch (error: any) {
    const errorResponse: ErrorResponse = {
      errors: error?.response?.data?.errors || errorMessageDefault,
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
    };
    return Promise.reject(errorResponse);
  }
}
