import AlbertsonsLogo from '@albertsons/uds/atom/logo/AlbertsonsLogo/AlbertsonsLogo';
import Card from '@albertsons/uds/molecule/Card';
import Input from '@albertsons/uds/molecule/Input';
import Select from '@albertsons/uds/molecule/Select';

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { LoginFormModel } from '../../../models/login-model';
import { useDispatch, useSelector } from 'react-redux';
import { postRegisterVendorCheckInDataAsync } from '../../../redux/reducers/vendorCheckInSlice';
import { RootState } from '../../../config/redux/store';
import { StoreInfoState } from '../../../redux/reducers/storeInfoSlice';
import { useNavigate } from 'react-router-dom';
import { getXSwyTimeZone, saveUserInfoamtion } from '../../../services/token.service';
import { SuccessButton } from '../../../shared/styled-components/styled-buttons';
import { Purpose, Vendor } from '../../../models/vendor-checkIn-model';

const LoginComponent: React.FC = () => {
  const { storeInfo } = useSelector<RootState, StoreInfoState>((state) => state.globalVendorCheckIn.storeInfo);

  const storeId = storeInfo?.stores[0]?.storeId;

  const [purposes, setPurposes] = useState<Purpose[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { register, handleSubmit, formState, control } = useForm<LoginFormModel>({
    defaultValues: {
      firstName: '',
      lastName: '',
      vendorId: '',
      purposeId: '',
      notes: ''
    },
    mode: 'all'
  });

  const { errors, isValid } = formState;

  const onSubmit = (data) => {
    const vendorCheckIn = {
      checkinTime: new Date().toISOString(),
      storeId,
      ...data
    };

    const headers = {
      'x-swy-timezone': getXSwyTimeZone()
    };
    dispatch(postRegisterVendorCheckInDataAsync({ vendorCheckIn, headers }))
      .unwrap()
      .then(() => {
        navigate('/checkin-summary');
        saveUserInfoamtion(data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setPurposes(storeInfo.stores[0]?.purposes);
    setVendors(storeInfo.stores[0]?.vendors);
  }, [storeInfo.stores]);

  return (
    <div className='container mx-auto px-4 sm:px-6 lg:px-8'>
      <div className='mt-2 mb-4 text-center flex justify-center'>
        <div className='flex justify-center flex-col items-center'>
          <AlbertsonsLogo className='mt-2 mb-2' />
          <h2 className='text-lg sm:text-xl md:text-2xl lg:text-3xl'>Vendor Registration</h2>
        </div>
      </div>

      <Card className='w-full max-w-lg'>
        <Card.Header>
          <div className='flex items-center justify-center w-full'>
            <h3>Login/Sign-Up</h3>
          </div>
        </Card.Header>
        <Card.Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid grid-cols-2 gap-x-6 gap-y-2'>
              <div className='col-span-2'>
                <Input
                  label='First Name'
                  placeholder='Enter your first name'
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: 'First name is required'
                    }
                  })}
                  isRequired
                  error={errors.firstName?.message}
                />
              </div>
              <div className='col-span-2'>
                <Input
                  label='Last Name'
                  placeholder='Enter your last name'
                  {...register('lastName', {
                    required: {
                      value: true,
                      message: 'Last name is required'
                    }
                  })}
                  isRequired
                  error={errors.lastName?.message}
                />
              </div>
              <div className='col-span-2'>
                <div className='flex items-center'>
                  <span className='text-sm font-bold whitespace-nowrap select-none truncate'>Company</span>
                  <span className='text-sm font-bold whitespace-nowrap select-none ml-1 text-error'>*</span>
                </div>
                <Controller
                  name='vendorId'
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Company name is required'
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      className='w-full'
                      itemText='company'
                      placeholder='Select your company'
                      onChange={(item) => {
                        field.onChange(item.vendorId); // Notify react-hook-form Controller about the change
                      }}
                      items={vendors}
                      value={vendors.find((vendor) => vendor.vendorId.toString() === field.value)}
                      // Find the item with num matching the field value and set it as the value prop
                    />
                  )}
                />
                <div className='flex justify-between items-center w-full'>
                  <div className='w-full text-sm leading-4 font-bold text-error mt-1 select-none whitespace-nowrap truncate'>
                    {errors && errors?.vendorId?.message ? errors.vendorId.message : ''}
                  </div>
                </div>
              </div>
              <div className='col-span-2'>
                <div className='flex items-center'>
                  <span className='text-sm font-bold whitespace-nowrap select-none truncate'>Purpose</span>
                  <span className='text-sm font-bold whitespace-nowrap select-none ml-1 text-error'>*</span>
                </div>
                <Controller
                  name='purposeId'
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Purpose is required'
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      className='w-full'
                      itemText='name'
                      placeholder='Enter the purpose of your visit'
                      onChange={(item) => {
                        field.onChange(item.purposeId); // Notify react-hook-form Controller about the change
                      }}
                      items={purposes}
                      value={purposes.find((purpose) => purpose.purposeId.toString() === field.value)}
                      // Find the item with num matching the field value and set it as the value prop
                    />
                  )}
                />
                <div className='flex justify-between items-center w-full'>
                  <div className='w-full text-sm leading-4 font-bold text-error mt-1 select-none whitespace-nowrap truncate'>
                    {errors && errors?.purposeId?.message ? errors.purposeId.message : ''}
                  </div>
                </div>
              </div>
              <div className='col-span-2'>
                <Input
                  label='Additional Info'
                  placeholder='Enter any additional notes (optional)'
                  {...register('notes')}
                />
              </div>
            </div>
            <SuccessButton type='submit' className='mt-2' disabled={!isValid}>
              Submit
            </SuccessButton>
          </form>
        </Card.Content>
      </Card>
    </div>
  );
};

export default LoginComponent;
