import React from 'react';

import './banner-bottom.scss';

interface BannerBottomProps {
  heading: string;
  subtext: string;
  onEnableClick: () => void;
}

const BannerBottom: React.FC<BannerBottomProps> = ({ heading, subtext, onEnableClick }) => {
  return (
    <div className='banner-bottom'>
      <h2 className='banner-heading'>{heading}</h2>
      <p className='banner-subtext'>{subtext}</p>
      <button className='banner-button' onClick={onEnableClick}>
        Enable
      </button>
    </div>
  );
};

export default BannerBottom;
