import { BaseService } from '../config/request';
import { VendorCheckInApiResponseModel, APIAsyncHeaders, CheckoutBodyModel } from '../models/login-model';

export async function postVendorCheckInData(
  formData: any,
  headers: APIAsyncHeaders
): Promise<VendorCheckInApiResponseModel> {
  return (await BaseService.postAsync(`checkin`, formData, headers)) as VendorCheckInApiResponseModel;
}

export async function postVendorCheckoutData(
  body: CheckoutBodyModel,
  headers: APIAsyncHeaders
): Promise<VendorCheckInApiResponseModel> {
  return (await BaseService.postAsync(`checkout`, body, headers)) as VendorCheckInApiResponseModel;
}
