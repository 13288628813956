import { ErrorResponse } from '../error-response';
import { errorMessageDefault } from './messages';
import instance from './instanceCreate';
import { AxiosRequestConfig } from 'axios';

/**
 * @description
 ** EN: Base method for POST requests
 * @param method
 ** NOTE: In case of the baseURL is not configured in the application
 * must be passed the entire URL. EX: https://localhost:44336/api/access
 */
export async function postAsync<Values>(method: string): Promise<Values | undefined>;
/**
 * @description
 ** EN: Base method for POST requests
 * @param method
 ** EN API method to be consumed. EX: baseURL/{method} = baseURL/access
 ** NOTE: In case of the baseURL is not configured in the application
 * must be passed the entire URL. EX: https://localhost:44336/api/access
 * @param body
 ** EN: Object that will be send in the body of request
 */
export async function postAsync<Values>(method: string, body: any, headers: any): Promise<Values | undefined>;
export async function postAsync<Values>(method: string, body?: any, headers?: any): Promise<Values | undefined> {
  try {
    const axiosRequestConfig: AxiosRequestConfig = { headers: headers };
    const response = await instance.post(method, body, axiosRequestConfig);
    const typedResponse: Values = response.data;
    return typedResponse;
  } catch (error: any) {
    const errorResponse: ErrorResponse = {
      errors: error?.response?.data?.errors || errorMessageDefault,
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
      errorMessage: error?.response?.data?.errorMessage
    };
    return Promise.reject(errorResponse);
  }
}
