import React, { useState, useEffect } from 'react';
import { slides } from "../../constants/constants";

const CarouselComponent: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, 3000); // Change slide every 3 seconds

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div
      className='relative max-w-lg mx-auto'
      style={{
        width: '288px'
      }}
    >
      <div className='overflow-hidden' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div
          className='flex transition-transform duration-500'
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((slide) => (
            <div key={slide.id} className='w-full flex-shrink-0 '>
              <div
                className='p-4 text-center rounded-lg flex flex-col justify-center items-center'
                style={{ backgroundColor: slide.bgColor }}
              >
                <div className='m-2'>{slide.node}</div>
                <div> {slide.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='flex justify-center mt-4'>
        {slides.map((slide, index) => (
          <button
            key={slide.id}
            style={{
              width: '16px',
              height: '16px',
              margin: '0 4px',
              borderRadius: '50%',
              backgroundColor: currentIndex === index ? '#333333' : '#cccccc',
              border: 'none',
              cursor: 'pointer'
            }}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CarouselComponent;
