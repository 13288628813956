import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: relative; /* Tailwind's relative */
  margin-top: 5rem; /* Tailwind's mt-20 */

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background: rgba(45, 45, 45, 10%);
    border-radius: 50%; /* Make the shadow circular */

    transform: translate(-50%, -50%);
  }
`;

export const StyledIconWrapper = styled.div`
  font-size: xxx-large;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
`;

export const ButtonContainer = styled.div`
  margin-top: auto; /* Pushes the button to the bottom */
`;
