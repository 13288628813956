import { IoCalendarClear, IoLocation } from 'react-icons/io5';

import { CheckinSummaryTableColumnModel } from '../../models/checkins-model';
import { LocationImg } from '../svgs/Location';
import { NFCImage } from '../svgs/Nfc-tag';
import { NotesImg } from '../svgs/Notes';

export const slides = [
  {
    id: 1,
    node: <NFCImage />,
    subHeading: 'NFC Tap for Easy Check-In and Check-Out',
    text: 'Use your NFC-enabled device to quickly and conveniently check in and check out at Albertsons store locations. Simply tap your device on the designated NFC point to complete the process.',
    bgColor: '#FFF8F0'
  },
  {
    id: 2,
    node: <LocationImg />,
    subHeading: 'Enable location services',
    text: `For a seamless check-in and check-out experience. Your location data will help us verify that you're within the store's geofence, ensuring secure access and accurate records.`,
    bgColor: '#FFF8F0'
  },
  {
    id: 3,
    node: <NotesImg />,
    subHeading: 'Check-In/Check-Out Summary',
    text: `Easily review your check-in and check-out history. The summary page provides detailed information about your visits to each store, including the date, time, and duration of your visit.`,
    bgColor: '#FFF8F0'
  }
];

export const Intro = 'Introducing Vendor Check-in';
export const PwaInfo = 'PWA that facilitates seamless vendor check-in and check-out experiences';

export const Continue = 'Continue';

export const WelcomePageBgColor = '#305DA2';

export const Confirm = 'Confirm';
export const CheckoutConfirmString = 'Do you want to checkout?';

export const CheckInSummaryTableColumn: CheckinSummaryTableColumnModel[] = [
  {
    id: 1,
    name: 'Store',
    field: 'storeId',
    icon: <IoLocation />
  },
  {
    id: 2,
    name: 'Date',
    field: 'checkinTs',
    icon: <IoCalendarClear />
  },
  {
    id: 3,
    name: 'Checked-In',
    field: 'checkinTs',
    icon: <></>
  },
  {
    id: 4,
    name: 'Checked-Out',
    field: 'checkoutTs',
    icon: <></>
  }
];

export const LocationSrvcsHeading = 'To check in using your mobile device, please enable location services.';
export const CheckOutusingNFC = 'If you choose not to enable location services, you can still check in using NFC';

export const AutoCheckedOut = 'Auto-checkedout';
export const CheckOut = 'Checkout';
export const CheckInSummary = 'Check-In Summary';
export const TotalDuration = 'Total Duration :';
export const TotalCheckIns = 'Total Check-ins :';
export const TotalCheckouts = 'Total Check-outs :';
export const NfcCheckInHelperText = "For NFC check-in, hold your device close to the NFC tag at the store's entrance.";
export const PurposeConstant = 'Purpose';
export const StoreConst = 'Store';
export const DISTANCE_TO_TRIGGER_VENDOR_PURPOSE_API = 10;
export const DISTACE_TO_TRIGGER_CHECKIN_SCREEN = 150;
