// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-bottom {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80vw;
  padding: 15px;
  border: 1px solid #000;
  border-radius: 10px;
}
.banner-bottom .banner-heading {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.banner-bottom .banner-subtext {
  font-size: 14px;
  margin-top: 5px;
  color: #947f52;
}
.banner-bottom .banner-button {
  display: inline-block;
  color: #fff;
  background-color: #009963;
  padding: 5px 20px;
  margin-top: 5px;
  border-radius: 100px;
}`, "",{"version":3,"sources":["webpack://./src/vendor-checkin/components/banner-bottom/banner-bottom.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;EACA,iBAAA;EACA,SAAA;AACN;AAEI;EACE,eAAA;EACA,eAAA;EACA,cAAA;AAAN;AAEI;EACI,qBAAA;EACA,WAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;EACA,oBAAA;AAAR","sourcesContent":[".banner-bottom {\r\n    position: fixed;\r\n    bottom: 10px;\r\n    left: 50%;\r\n    transform: translateX(-50%);\r\n    width: 80vw;\r\n    padding: 15px;\r\n    border: 1px solid #000;\r\n    border-radius: 10px;\r\n  \r\n    .banner-heading {\r\n      font-size: 16px;\r\n      font-weight: bold;\r\n      margin: 0;\r\n    }\r\n  \r\n    .banner-subtext {\r\n      font-size: 14px;\r\n      margin-top: 5px;\r\n      color: #947f52;\r\n    }\r\n    .banner-button{\r\n        display: inline-block;\r\n        color: #fff;\r\n        background-color: #009963;\r\n        padding: 5px 20px;\r\n        margin-top: 5px;\r\n        border-radius: 100px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
