import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const MessageBosx = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  position: relative;
  margin: 10px;
  background-color: #f44336;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const CloseButton = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Th = styled.th`
  text-align: left;
  font-weight: bold;
  color: black;
  text-decoration: underline;
`;

export const Td = styled.td`
  text-align: left;
  padding: 2px;
`;

export const GreenText = styled.span`
  color: #10b981; /* This is the hex value for Tailwind's text-green-500 */
`;
