import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from '../../screens/Home';
import PageNotFound from '../../screens/PageNotFound';
import CheckInSummaryPage from '../../vendor-checkin/components/check-in-summary/checkin-summary';
import CheckInDetailPage from '../../vendor-checkin/components/check-in-detail/checkin-detail';
import CheckoutComponent from '../../vendor-checkin/components/checkout/checkout-component';

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/checkout' element={<CheckoutComponent />} />
        <Route path='/checkin-summary' element={<CheckInSummaryPage />} />
        <Route path='/checkin-detail' element={<CheckInDetailPage />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
