import { CheckInSummaryParams, CheckinSummaryModel } from '../../models/checkins-model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startLoaderAsync, stopLoaderAsync } from './loaderSlice';

import { ErrorResponse } from '../../config/request/error-response';
import { fetchCheckInSummaryData } from '../../services/checkIns-summary.service';
import { openAlertAsync } from './alertSlice';

export interface CheckInSummaryState {
  checkInSummary: CheckinSummaryModel;
}

export const initialState: CheckInSummaryState = {
  checkInSummary: {
    checkIns: null,
    statusCode: null,
    totalCheckins: 0,
    totalCheckouts: 0,
    totalDuration: 0,
    isLoading: true
  }
};

export const fetchCheckinsSummaryDataAsync = createAsyncThunk(
  'checkInSummary/fetchCheckinsSummaryDataAsync',
  async (params: CheckInSummaryParams, { dispatch, rejectWithValue }) => {
    dispatch(resetCheckins());
    dispatch(setLoadingFlagTrue());
    try {
      dispatch(startLoaderAsync());
      const response = await fetchCheckInSummaryData(params);
      return response;
    } catch (error: any) {
      console.log('Error', error);
      const errorResponse: ErrorResponse = error;
      dispatch(openAlertAsync(errorResponse.errorMessage ?? errorResponse?.errors[0]?.errorMessage));
      return rejectWithValue(error);
    } finally {
      dispatch(setLoadingFlagFalse());
      dispatch(stopLoaderAsync());
    }
  }
);

const checkInSummarySlice = createSlice({
  name: 'checkInSummary',
  initialState,
  reducers: {
    resetCheckins: (state) => {
      state.checkInSummary = initialState.checkInSummary;
    },
    setLoadingFlagTrue: (state) => {
      state.checkInSummary.isLoading = true;
    },
    setLoadingFlagFalse: (state) => {
      state.checkInSummary.isLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCheckinsSummaryDataAsync.fulfilled, (state, action) => {
      state.checkInSummary = action.payload as CheckinSummaryModel;
    });
  }
});

export const { resetCheckins, setLoadingFlagFalse, setLoadingFlagTrue } = checkInSummarySlice.actions;
export const checkInSummaryReducer = checkInSummarySlice.reducer;
