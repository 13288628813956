import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import './checkin-detail.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../config/redux/store';
import { Select } from '@albertsons/uds/molecule/Select';
import { postVendorCheckInDataAsync } from '../../../redux/reducers/vendorCheckInSlice';
import { getDeviceToken, getStoreId, getUserInfo, saveStoreId } from '../../../services/token.service';
import { APIAsyncHeaders, LoginFormModel, RegisterVendorCheckInParams } from '../../../models/login-model';
import { StoreInfoState, fetchStoreInfoDataAsync } from '../../../redux/reducers/storeInfoSlice';
import { SuccessButton } from '../../../shared/styled-components/styled-buttons';
import { Purpose } from '../../../models/vendor-checkIn-model';
import { NfcCheckInHelperText, PurposeConstant, StoreConst } from '../../../shared/constants/constants';
import { LocationImg } from '../../../shared/svgs/Location';

const CheckInDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deviceToken, setDeviceToken] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<LoginFormModel | null>(null);
  const [purposes, setPurposes] = useState<[] | Purpose[]>([]);
  const [headers, setHeaders] = useState<APIAsyncHeaders | {}>({});
  const [vendorName, setVendorName] = useState<string | null>(null);
  const [storeId, setStoreId] = useState<null | string>(null);

  const { storeInfo } = useSelector<RootState, StoreInfoState>((state) => state.globalVendorCheckIn.storeInfo);

  const banner = storeInfo.stores?.length > 0 ? storeInfo.stores[0]?.name : '';

  useEffect(() => {
    async function fetchData() {
      const storeId = await getStoreId();
      setStoreId(storeId);

      if (storeInfo.stores?.length < 0 || !storeInfo.stores[0]?.storeId) {
        dispatch(fetchStoreInfoDataAsync({ storeId }))
          .unwrap()
          .then(() => {
            if (storeInfo.stores?.length > 0 && storeInfo.stores[0]?.storeId) {
              saveStoreId(storeInfo.stores[0]?.storeId);
            }
          });
      }
    }
    fetchData();
  }, [dispatch, storeId, storeInfo.stores]);

  const purposeStore = useSelector(
    (state: RootState) => state.globalVendorCheckIn.vendorCheckIn.vendorCheckIn.purposeId
  ); // Accessing store name from Redux store

  const [purpose, setPurpose] = useState(purposeStore);

  const handleCheckin = () => {
    const currentTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    // dispatch(setCheckinTime(currentTime));
    const vendorCheckIn: RegisterVendorCheckInParams = {
      deviceToken: deviceToken as string,
      vendorId: userInfo?.vendorId ?? '',
      purposeId: purpose,
      firstName: userInfo?.firstName ?? '',
      lastName: userInfo?.lastName ?? '',
      isCheckedIn: false,
      notes: '',
      storeId: storeId as string,
      checkinTime: currentTime
    };
    dispatch(
      postVendorCheckInDataAsync({
        vendorCheckIn,
        headers: headers as APIAsyncHeaders
      })
    )
      .unwrap()
      .then(() => {
        navigate('/checkin-summary');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDeviceToken().then((token) => {
      if (!token) {
        navigate('/');
      } else {
        setDeviceToken(token);
      }
    });
  }, [navigate]);

  useEffect(() => {
    const userInfoFromLocalStorage = getUserInfo() as LoginFormModel;
    setUserInfo(userInfoFromLocalStorage);
  }, []);

  useEffect(() => {
    const purposes = storeInfo.stores?.length > 0 ? storeInfo?.stores[0]?.purposes : [];
    setPurposes(purposes);
    const headers = {
      'x-swy-timezone': storeInfo?.stores[0]?.timezone
    };
    setHeaders(headers);
  }, [storeInfo]);

  useEffect(() => {
    if (userInfo?.vendorId) {
      const vendorDetails =
        storeInfo.stores?.length > 0
          ? storeInfo.stores[0]?.vendors.filter((vendor) => String(vendor.vendorId) === String(userInfo.vendorId))
          : [];
      setVendorName(vendorDetails[0]?.company);
    }
  }, [storeInfo.stores, userInfo?.vendorId]);

  return (
    <div>
      <nav className='navbar'>
        <span className='back-button' onClick={() => navigate('/checkin-summary')}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
        <h1 className='page-title'>Check-In</h1>
      </nav>
      <div className='checkin-detail-content'>
        <div className='top-bar'>
          <div className='left'>
            <LocationImg />
          </div>
          <div className='right'>
            <h1>{vendorName}</h1>
            <p>
              {banner} <br />
              {StoreConst} {storeId}
            </p>
          </div>
        </div>
        <div className='content'>
          <h4>{PurposeConstant}</h4>
          <Select
            className='w-full'
            itemText='name'
            placeholder='Enter the purpose of your visit'
            items={purposes}
            onChange={(item) => {
              setPurpose(item.purposeId); // Notify react-hook-form Controller about the change
            }}
            value={purposes.find((purposeId) => purposeId.name.toString() === purpose)}
          />
          <SuccessButton onClick={handleCheckin} className='greenbutton' disabled={!purpose}>
            Check-In
          </SuccessButton>
          <p>{NfcCheckInHelperText}</p>
        </div>
      </div>
    </div>
  );
};

export default CheckInDetailPage;
